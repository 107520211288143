<template>
  <v-container>
    <v-dialog v-model="labelDialog" width="500">
      <v-card>
        <v-card-title>Associa etichetta</v-card-title>
        <v-card-text>
          Inserire il codice etichetta o utilizzare il lettore di QRCode:
          <v-text-field v-model="label" label="Codice etichetta"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn :disabled="label.length !== 24" @click="assignLabel()"
            >Associa</v-btn
          >
          <v-btn @click="labelDialog = false">Annulla</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-card v-show="loading" elevation="64" min-height="600">
      <v-card-text>
        <v-row justify="center">
          <v-col cols="2">
            <br />
            <br />
            <br />
            <v-progress-circular
              color="primary"
              indeterminate
              :size="70"
              :width="7"
            />
            <br />
            <br />
            <br />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-snackbar
      centered
      v-model="permSnackbar"
      :color="snackbarColor"
      :timeout="timeout"
    >
      {{ errorText }}
      <template v-slot:action="{ attrs }">
        <v-btn icon text v-bind="attrs" @click="permSnackbar = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>

    <v-dialog v-model="roomDialog" max-width="800">
      <v-card>
        <v-card-title>
          <span class="text-h5">Spostamento bene</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedRoom"
            :loading="roomsLoading"
            :items="rooms"
            :search-input.sync="search"
            item-text="text"
            item-value="ID"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Ricerca stanza"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="moveAsset()"
            :disabled="selectedRoom === null"
            color="primary"
            class="ma-2"
            >Sposta</v-btn
          >
          <v-btn @click="roomDialog = false" color="primary" class="ma-2"
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="userDialog" max-width="800">
      <v-card>
        <v-card-title>
          <span class="text-h5">Assegnazione bene</span>
        </v-card-title>
        <v-card-text>
          <v-autocomplete
            v-model="selectedUser"
            :loading="usersLoading"
            :items="users"
            :search-input.sync="searchuser"
            item-text="text"
            item-value="MATRICOLA"
            cache-items
            class="mx-4"
            flat
            hide-no-data
            hide-details
            label="Ricerca persona"
          ></v-autocomplete>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            @click="assignAsset()"
            :disabled="selectedUser === null"
            color="primary"
            class="ma-2"
            >Assegna</v-btn
          >
          <v-btn @click="userDialog = false" color="primary" class="ma-2"
            >Annulla</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row v-if="assetFound">
      <v-col cols="12">
        <v-card>
          <v-card-title v-if="asset.DT_BUONO_SCARICO" class="justify-center"
            ><v-row>
              <v-col cols="2"></v-col
              ><v-col cols="8" class="red--text">BENE SCARICATO</v-col></v-row
            ></v-card-title
          >
          <v-card-title v-if="asset.PG_BENE_SUB > 0" class="justify-center"
            ><v-row>
              <v-col cols="2"></v-col
              ><v-col cols="8">BENE SUBORDINATO</v-col></v-row
            ></v-card-title
          >
          <v-card-title v-else class="justify-center"
            ><v-row>
              <v-col cols="2"></v-col
              ><v-col cols="8">BENE PRINCIPALE</v-col></v-row
            ></v-card-title
          >

          <v-card-text>
            <v-row v-for="(value, field) in asset" :key="field">
              <v-row
                v-if="toShow(field, value) && labelMappingAsset[field].visible"
                align="end"
              >
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right"
                  >{{
                    labelMappingAsset[field].label != ""
                      ? labelMappingAsset[field].label
                      : field
                  }}:</v-col
                >
                <v-col cols="8">
                  <v-text-field
                    v-if="
                      localData(field) &&
                      (userRole === 'admin' || userRole === 'operator')
                    "
                    v-model="asset[field]"
                    :value="value"
                    placeholder="valore"
                    hide-details="auto"
                  ></v-text-field>
                  <div v-else-if="field === 'VALORE_CONVENZIONALE'">
                    &euro; {{ value }}
                  </div>
                  <div v-else-if="field === 'LAST_SEEN'">
                    {{ formatDate(value) }}
                  </div>
                  <div v-else>
                    {{ value }}
                  </div>
                </v-col>
                <v-col cols="1"> </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <v-row>
              <v-row align="end">
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start"
                  >PROGETTI (FONDI):</v-col
                >
                <v-col cols="8">
                  <div v-for="project of asset.Projects" :key="project.id">
                    <v-tooltip left>
                      <template v-slot:activator="{ on, attrs }">
                        <div v-bind="attrs" v-on="on">
                          {{ (project.PER_RIP_ANALITICO * 100).toFixed(2) }}%
                          {{ project.CD_PROGETTO }}
                          (&euro;
                          {{
                            (
                              project.PER_RIP_ANALITICO *
                              asset.VALORE_CONVENZIONALE
                            ).toFixed(2)
                          }})
                        </div>
                      </template>
                      <span>{{ project.NOME_PROGETTO }}</span>
                    </v-tooltip>
                  </div>
                </v-col>
                <v-col cols="1"> </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <v-row v-if="asset.PG_BENE_SUB === 0">
              <v-row align="end">
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start"
                  >ULTIMO ASSEGNATARIO:</v-col
                >
                <v-col cols="4">
                  <div v-if="currentOwner.MATRICOLA">
                    <router-link :to="'/users/' + currentOwner.MATRICOLA">{{
                      currentOwner.name
                    }}</router-link
                    >{{ currentOwner.text }}
                  </div>
                  <div v-else>
                    {{ currentOwner.name }}
                  </div>
                  <br />
                </v-col>
                <v-col cols="5" align-self="start">
                  <v-tooltip top v-if="!asset.DT_BUONO_SCARICO">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="userRole === 'admin' || userRole === 'operator'"
                        v-bind="attrs"
                        v-on="on"
                        @click="userDialog = true"
                      >
                        <v-icon>mdi-account-switch</v-icon>
                      </v-btn>
                    </template>
                    <span>Riassegna</span>
                  </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <v-row v-if="asset.PG_BENE_SUB === 0">
              <v-row align="end">
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start"
                  >PRECEDENTI ASSEGNATARI:</v-col
                >
                <v-col cols="8" align-self="start">
                  <div v-if="!previousOwners[0].MATRICOLA">
                    {{ previousOwners[0].name }}
                  </div>
                  <div v-else>
                    <div v-for="(owner, index) of previousOwners" :key="index">
                      <router-link :to="'/users/' + owner.MATRICOLA">{{
                        owner.name
                      }}</router-link
                      >{{ owner.text }}
                    </div>
                  </div>
                  <br />
                </v-col>
                <v-col cols="1"> </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <v-row v-if="asset.PG_BENE_SUB === 0">
              <v-row align="end">
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start"
                  >ULTIMA POSIZIONE:</v-col
                >
                <v-col cols="4" align-self="start">
                  <div v-if="currentLocation.ID">
                    <router-link :to="'/rooms/' + currentLocation.ID">{{
                      currentLocation.name
                    }}</router-link
                    >{{ currentLocation.text }}
                  </div>
                  <div v-else>
                    {{ currentLocation.name }}
                  </div>
                  <br />
                </v-col>
                <v-col cols="5" align-self="start">
                  <v-tooltip top v-if="!asset.DT_BUONO_SCARICO">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="userRole === 'admin' || userRole === 'operator'"
                        v-bind="attrs"
                        v-on="on"
                        @click="roomDialog = true"
                      >
                        <v-icon>mdi-dolly</v-icon>
                      </v-btn>
                    </template>
                    <span>Sposta</span>
                  </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <v-row v-if="asset.PG_BENE_SUB === 0">
              <v-row align="end">
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start"
                  >PRECEDENTI POSIZIONI:</v-col
                >
                <v-col cols="8" align-self="start">
                  <div v-if="!previousLocations[0].ID">
                    {{ previousLocations[0].name }}
                  </div>
                  <div v-else>
                    <div
                      v-for="(location, index) of previousLocations"
                      :key="index"
                    >
                      <router-link :to="'/rooms/' + location.ID">{{
                        location.name
                      }}</router-link
                      >{{ location.text }}
                    </div>
                  </div>
                  <br />
                </v-col>
                <v-col cols="1"> </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <v-row v-if="asset.PG_BENE_SUB === 0">
              <v-row align="end">
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start"
                  >CODICE ETICHETTA:</v-col
                >
                <v-col cols="4">
                  <div v-if="asset.AssetTagId">
                    {{ asset.AssetTag.longCode }} (<strong>{{
                      asset.AssetTag.shortCode
                    }}</strong
                    >)
                  </div>
                  <div v-else>N/A</div>
                  <br />
                </v-col>
                <v-col cols="5" align-self="start">
                  <v-tooltip top v-if="asset.AssetTag && !asset.DT_BUONO_SCARICO">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="userRole === 'admin' || userRole === 'operator'"
                        v-bind="attrs"
                        v-on="on"
                        @click="
                          label = '';
                          assignLabel();
                        "
                      >
                        <v-icon>mdi-trash-can-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Rimuovi etichetta</span>
                  </v-tooltip>
                  <v-tooltip top v-if="!asset.DT_BUONO_SCARICO">
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="userRole === 'admin' || userRole === 'operator'"
                        v-bind="attrs"
                        v-on="on"
                        @click="initReader()"
                      >
                        <v-icon>mdi-text-box-multiple-outline</v-icon>
                      </v-btn>
                    </template>
                    <span>Associa etichetta</span>
                  </v-tooltip>
                </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
            <v-row>
              <v-row align="end">
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right" align-self="start"
                  >IMMAGINI:</v-col
                >
                <v-col cols="8" align-self="start">
                  <div v-if="asset.Pictures.length <= 1">
                    <v-card>
                      <v-img
                        v-for="picture of asset.Pictures"
                        :key="picture.id"
                        class="ma-2"
                        height="300"
                        max-width="500"
                        contain
                        :src="imageUrl(picture.id)"
                      >
                        <v-tooltip
                          left
                          v-if="userRole === 'admin' || userRole === 'operator'"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              fab
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteImage(picture.id)"
                            >
                              <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </template>
                          <span>Elimina</span>
                        </v-tooltip>
                      </v-img>
                    </v-card>
                  </div>
                  <div v-else style="width: 400px">
                    <v-carousel
                      height="300"
                      cycle
                      progress
                      dark
                      light
                      hide-delimiters
                    >
                      <v-carousel-item
                        v-for="picture of asset.Pictures"
                        :key="picture.id"
                        :src="imageUrl(picture.id)"
                        max-height="300"
                        contain
                      >
                        <v-tooltip
                          left
                          v-if="userRole === 'admin' || userRole === 'operator'"
                        >
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn
                              small
                              fab
                              v-bind="attrs"
                              v-on="on"
                              @click="deleteImage(picture.id)"
                            >
                              <v-icon>mdi-trash-can</v-icon>
                            </v-btn>
                          </template>
                          <span>Elimina</span>
                        </v-tooltip>
                      </v-carousel-item>
                    </v-carousel>
                  </div>
                  <!--v-text-field
                  id="img"
                  type="text"
                  required
                  readonly
                  placeholder="Immagine"
                  label="Immagine"
                  @click="$refs.imageUpload.$refs.input.click()"
                /-->
                  <v-file-input
                    v-if="userRole === 'admin' || userRole === 'operator'"
                    ref="imageUpload"
                    accept="image/*"
                    label="Aggiungi immagine"
                    @change="uploadImage"
                  />
                </v-col>
                <v-col cols="1"> </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
          </v-card-text>
          <v-card-title v-if="techDataPermitted()" class="justify-center"
            ><v-row>
              <v-col cols="2"></v-col
              ><v-col cols="8">SCHEDA TECNICA</v-col></v-row
            ></v-card-title
          >
          <v-card-text v-if="techDataPermitted()">
            <v-row>
              <v-row align="start">
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right">TIPOLOGIA:</v-col>
                <v-col cols="8">
                  <v-select
                    :items="techTypes"
                    v-if="userRole === 'admin' || userRole === 'operator'"
                    v-model="asset.TECH_TYPE"
                    label="Tipologia"
                  ></v-select>
                  <div v-else>{{ asset.TECH_TYPE }}</div>
                </v-col>
                <v-col cols="1"> </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
          </v-card-text>

          <v-card-text v-if="techDataPermitted() && asset.TECH_TYPE">
            <v-row v-for="(value, field) in asset" :key="field">
              <v-row
                v-if="techData(field) && labelMappingAsset[field].visible"
                align="end"
              >
                <v-spacer></v-spacer>
                <v-col cols="3" class="text-right"
                  >{{
                    labelMappingAsset[field].label != ""
                      ? labelMappingAsset[field].label
                      : field
                  }}:</v-col
                >
                <v-col cols="8">
                  <v-text-field
                    v-if="techData(field)"
                    v-model="asset[field]"
                    :value="value"
                    placeholder="valore"
                    hide-details="auto"
                  ></v-text-field>
                  <div v-else-if="field === 'VALORE_CONVENZIONALE'">
                    &euro; {{ value }}
                  </div>
                  <div v-else-if="field === 'LAST_SEEN'">
                    {{ formatDate(value) }}
                  </div>
                  <div v-else>
                    {{ value }}
                  </div>
                </v-col>
                <v-col cols="1"> </v-col>
                <v-spacer></v-spacer>
              </v-row>
            </v-row>
          </v-card-text>

          <br />
          <br />
          <v-card-actions>
            <br />
            <v-spacer></v-spacer>
            <v-btn
              v-if="userRole === 'admin' || userRole === 'operator'"
              @click="saveAsset"
            >
              Salva
            </v-btn>
            <v-btn @click="$router.go(-1)"> Annulla </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-text>
            <v-row>
              <v-row>
                <v-col cols="12">
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header
                        >Beni correlati</v-expansion-panel-header
                      >
                      <v-expansion-panel-content>
                        <related-assets
                          :assets="asset.relatedAssets"
                        ></related-assets>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
const moment = require("moment-timezone");
import Vue from "vue";
import RelatedAssets from "../components/RelatedAssets.vue";
import { mapState } from "vuex";
import RFID_Reader from "../utils/rfid_lib/rfid_reader.js";

// Read sound
import { useSound } from "@vueuse/sound";
import readSfxWav from "../assets/read_code.wav";

export default {
  name: "Asset",
  components: { RelatedAssets },
  setup() {
    const { play } = useSound(readSfxWav);
    return {
      play,
    };
  },
  data: () => {
    return {
      labelDialog: false,
      label: "",
      techTypes: ["PC", "LAPTOP", "SERVER", "TABLET", "MONITOR", "PRINTER"],
      asset: {},
      permSnackbar: false,
      snackbarColor: "success",
      errorText: "",
      assetFound: false,
      timeout: 2000,
      loading: false,
      roomDialog: false,
      userDialog: false,
      selectedRoom: null,
      selectedUser: null,
      roomsLoading: false,
      usersLoading: false,
      rooms: [],
      users: [],
      search: null,
      searchuser: null,
    };
  },
  watch: {
    search(val) {
      val && val !== this.selectedRoom && this.queryRooms(val);
    },
    searchuser(val) {
      val && val !== this.selectedUser && this.queryUsers(val);
    },
    $route() {
      this.loadAsset();
    },
  },
  mounted: function () {
    this.loadAsset();
  },
  computed: {
    ...mapState(["userInitials", "userRole", "labelMappingAsset"]),
    currentOwner() {
      if (this.asset.Users.length === 0) {
        return { name: "N/A", MATRICOLA: null, text: "" };
      }
      for (let user of this.asset.Users) {
        if (!user.AssetUser.ENDDATE) {
          return {
            name: user.COGNOME + " " + user.NOME,
            text:
              ", dal " +
              moment(user.AssetUser.STARTDATE)
                .tz("Europe/Rome")
                .locale("IT")
                .format("D MMM YYYY"),
            MATRICOLA: user.MATRICOLA,
          };
        }
      }
      return { name: "N/A", MATRICOLA: null, text: "" };
    },
    previousOwners() {
      if (this.asset.Users.length === 0) {
        return [{ name: "N/A", text: "", MATRICOLA: null }];
      }
      let retval = [];
      let found = false;
      for (let user of this.asset.Users) {
        if (user.AssetUser.ENDDATE) {
          found = true;
          retval.push({
            name: user.COGNOME + " " + user.NOME,
            text:
              ", dal " +
              moment(user.AssetUser.STARTDATE)
                .tz("Europe/Rome")
                .locale("IT")
                .format("D MMM YYYY") +
              " al " +
              moment(user.AssetUser.ENDDATE)
                .tz("Europe/Rome")
                .locale("IT")
                .format("D MMM YYYY"),
            MATRICOLA: user.MATRICOLA,
          });
        }
      }
      if (found) {
        return retval;
      } else {
        return [{ name: "N/A", text: "", MATRICOLA: null }];
      }
    },
    currentLocation() {
      if (this.asset.Rooms.length === 0) {
        return { name: "N/A", ID: null, text: "" };
      }
      for (let room of this.asset.Rooms) {
        let roomName = "";
        if (room.LOCAL_NAME) {
          roomName = " (" + room.LOCAL_NAME + ")";
        } else if (room.RM_NAME) {
          roomName = " (" + room.RM_NAME + ")";
        }
        if (!room.AssetRoom.ENDDATE) {
          return {
            ID: room.ID,
            text:
              ", dal " +
              moment(room.AssetRoom.STARTDATE)
                .tz("Europe/Rome")
                .locale("IT")
                .format("D MMM YYYY"),
            name: room.ID + roomName,
          };
        }
      }
      return { name: "N/A", MATRICOLA: null, text: "" };
    },
    previousLocations() {
      if (this.asset.Rooms.length === 0) {
        return [{ name: "N/A", text: "", ID: null }];
      }
      let retval = [];
      let found = false;
      for (let room of this.asset.Rooms) {
        let roomName = "";
        if (room.LOCAL_NAME) {
          roomName = " (" + room.LOCAL_NAME + ")";
        } else if (room.RM_NAME) {
          roomName = " (" + room.RM_NAME + ")";
        }

        if (room.AssetRoom.ENDDATE) {
          found = true;
          retval.push({
            ID: room.ID,
            text:
              ", dal " +
              moment(room.AssetRoom.STARTDATE)
                .tz("Europe/Rome")
                .locale("IT")
                .format("D MMM YYYY") +
              " al " +
              moment(room.AssetRoom.ENDDATE)
                .tz("Europe/Rome")
                .locale("IT")
                .format("D MMM YYYY"),
            name: room.ID + roomName,
          });
        }
      }
      if (found) {
        return retval;
      } else {
        return [{ name: "N/A", text: "", ID: null }];
      }
    },
  },
  methods: {
    initReader() {
      if (!this.$rfid) {
        Vue.prototype.$rfid = new RFID_Reader();
        this.$rfid
          .discover()
          .then((response) => {
            // Device found we are now connected let's set the notify and disconnect methods
            // console.log(response);
            this.deviceName = this.$rfid.name;

            // Start only optical mode (no RFID)
            this.$rfid.startQRReader();

            // Set the callback to notify new readings by the reader
            this.$rfid.startNotification(this.handleNotification);
            this.$rfid.setDisconnectedCallback(this.onDeviceDisconnected);
          })
          .catch((response) => {
            console.log(response);
          });
      } else if (this.$rfid.isConnected) {
        this.$rfid.setDisconnectedCallback(this.onDeviceDisconnected);
        this.$rfid.startNotification(this.handleNotification);
      } else {
        // console.log("why here?");
      }
      this.labelDialog = true;
    },
    handleNotification(notification) {
      // // Migrazione di questa parte nella lib reader, qui mi deve già arrivare la notifica di evento pulita...
      // // qui riceviamo un noticeframe o un responseframe
      // let array = new Uint8Array(event.target.value.buffer);
      // //console.log("Handled notification: " + array);
      // //console.log(array);

      // let notification = this.$rfid.parseNotificationArrayBuffer(array);

      // We parsed a valid notification packet
      if (
        notification &&
        notification.type &&
        notification.value !== null &&
        this.labelDialog
      ) {
        switch (notification.type) {
          case "trigger":
            //console.log(notification.value);
            this.trigger_switch = notification.value;
            break;

          case "qrcode":
            this.qrRead = notification.value;
            // console.log(this.qrRead);
            // Play sound after a code was red
            this.play();
            if (this.qrRead) {
              this.label = this.qrRead.replace(/[^a-zA-Z0-9 -:]/g, "");
              this.assignLabel();
            }
            break;

          case "rfid":
            if (notification.value.EPC != 0) {
              this.tagRead = notification.value.EPC;
              this.tagReadRSSI = notification.value.RSSI;
              // Play sound after a code was red
              this.play();
            }
        }
      }
    },
    onDeviceDisconnected() {
      // console.log("Device was disconnected...");

      this.$rfid.removeNotificationCallback(this.handleNotification);
      this.$rfid.removeDisconnectedCallback(this.onDeviceDisconnected);

      Vue.prototype.$rfid = null;
    },
    imageUrl(id) {
      return process.env.VUE_APP_PATRIMONIO_API + "/assets/image/" + id;
    },
    deleteImage(imageID) {
      this.$http
        .delete(
          process.env.VUE_APP_PATRIMONIO_API + "/assets/image/" + imageID,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          //this.loadAsset();
          this.loadImages();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
        });
    },
    assignLabel() {
      let label = null;
      let component = this;
      if (this.label !== "") {
        label = this.label;
      }
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/label/" +
            this.$route.params.id,
          {
            label: label,
          },
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          component.loading = false;
          if (label) {
            component.errorText = "Etichetta associata correttamente";
          } else {
            component.errorText = "Etichetta rimossa correttamente";
          }
          component.snackbarColor = "success";
          component.timeout = 3000;
          component.permSnackbar = true;
          component.labelDialog = false;
          component.label = "";
          return this.loadAsset();
        })
        .then(() => {
          component.label = "";
          component.labelDialog = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;

          this.loading = false;
        });
    },
    moveAsset() {
      this.roomDialog = false;
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/" +
            this.$route.params.id +
            "/move/" +
            this.selectedRoom,
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Bene spostato correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          //this.loadAsset();
          return;
        })
        .then(() => {
          this.loadAsset();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;

          this.loading = false;
        });
    },
    assignAsset() {
      this.userDialog = false;
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/" +
            this.$route.params.id +
            "/assign/" +
            this.selectedUser,
          {},
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Bene assegnato correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
          this.loadAsset();
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;
          //this.errorText = err;

          this.loading = false;
        });
    },
    queryRooms(v) {
      this.roomsLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/rooms/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let room of response.data) {
            let name = room.ID;
            if (room.LOCAL_NAME) {
              name += " (" + room.LOCAL_NAME + ")";
            } else if (room.RM_NAME) {
              name += " (" + room.RM_NAME + ")";
            }
            room.text = name;
          }
          this.rooms = response.data;
          this.roomsLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
      // Simulated ajax query
      // setTimeout(() => {
      //   this.items = this.states.filter(e => {
      //     return (e || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1
      //   })
      //   this.loading = false
      // }, 500)

      // TODO load rooms from backend
      // },
    },
    queryUsers(v) {
      this.userLoading = true;
      this.$http
        .get(process.env.VUE_APP_PATRIMONIO_API + "/users/search/" + v, {
          headers: {
            authorization: "Bearer " + this.$store.state.authJwt,
          },
        })
        .then((response) => {
          for (let user of response.data) {
            let name = user.MATRICOLA + " " + user.COGNOME + " " + user.NOME;
            user.text = name;
          }
          this.users = response.data;
          this.usersLoading = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }

          this.roomsLoading = false;
        });
    },
    loadAsset() {
      this.loading = true;
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/" +
            this.$route.params.id,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.labelDialog = false;

          this.asset = response.data;
          if (response.data.AssetTag && response.data.AssetTag.longCode) {
            response.data.AssetTagId = response.data.AssetTag.longCode;
          }
          this.loading = false;
          this.assetFound = true;
          this.labelDialog = false;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;

          // setTimeout(this.back, this.timeout);

          this.loading = false;
          this.assetFound = false;
        });
    },
    back() {
      this.$router.go(-1);
    },
    loadImages() {
      this.$http
        .get(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/images/" +
            this.$route.params.id,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then((response) => {
          this.asset.Pictures = response.data;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.errorIT) {
            this.errorText = err.response.data.errorIT;
          } else if (
            err.response &&
            err.response.data &&
            err.response.data.error
          ) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
        });
    },
    formatDate(date) {
      return moment(date)
        .tz("Europe/Rome")
        .locale("IT")
        .format("D MMMM YYYY, H:mm");
    },
    uploadImage(file) {
      let formData = new FormData();
      let component = this;
      formData.append("file", file);
      this.$http
        .post(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/image/" +
            this.$route.params.id,
          formData,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then(function () {
          component.loadImages();
        })
        .catch(function (response) {
          console.log(response);
        });
    },
    saveAsset() {
      this.$http
        .put(
          process.env.VUE_APP_PATRIMONIO_API +
            "/assets/" +
            this.$route.params.id,
          this.asset,
          {
            headers: {
              authorization: "Bearer " + this.$store.state.authJwt,
            },
          }
        )
        .then(() => {
          this.loading = false;
          this.errorText = "Bene salvato correttamente";
          this.snackbarColor = "success";
          this.timeout = 3000;
          this.permSnackbar = true;
        })
        .catch((err) => {
          if (err.response && err.response.data && err.response.data.error) {
            this.errorText = err.response.data.error;
          } else {
            this.errorText = err;
          }
          this.snackbarColor = "error";
          this.permSnackbar = true;
          this.timeout = -1;
          this.errorText = err;

          this.loading = false;
        });
    },
    localData(field) {
      return field.startsWith("LOCAL_");
    },
    techData(field) {
      return (
        this.asset.TECH_TYPE && field.startsWith("TECH_" + this.asset.TECH_TYPE)
      );
    },
    toShow(field, value) {
      return (
        !Array.isArray(value) &&
        field !== "createdAt" &&
        field !== "updatedAt" &&
        (value !== null || this.localData(field))
      );
    },
    techDataPermitted() {
      return (
        this.asset.PG_BENE_SUB === 0 &&
        this.asset.CD_CATEG_GRUPPO.startsWith("I.M.03")
      );
    },
    filterAssets(assets, current) {
      let filtered = [];
      for (let asset of assets) {
        if (asset.DT_BUONO_SCARICO) {
          continue;
        }
        if (asset.UserAsset.ENDDATE === null) {
          if (current) {
            filtered.push(JSON.parse(JSON.stringify(asset)));
          }
        } else {
          if (!current) {
            filtered.push(JSON.parse(JSON.stringify(asset)));
          }
        }
      }
      return filtered;
    },
    filterRooms(rooms, current) {
      //return rooms
      let filtered = [];
      for (let room of rooms) {
        if (room.UserRoom.ENDDATE === null) {
          if (current) {
            filtered.push(JSON.parse(JSON.stringify(room)));
          }
        } else {
          if (!current) {
            filtered.push(JSON.parse(JSON.stringify(room)));
          }
        }
      }
      return filtered;
    },
  },
};
</script>